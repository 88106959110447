import React, { useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import "./../App.css";
import { TextAds,TagAds } from './AdsComponent';

const Header = () => {
  const generateRandomCode = () => {
    let code = '';
    while (code.length < 18) {
      code += Math.random().toString(36).substr(2);
    }
    return code.slice(0, 18); 
  };
  
  const saveCodeToLocalStorage = () => {
    const existingCode = localStorage.getItem('cookiecode');
    if (!existingCode) {
      const code = generateRandomCode();
      localStorage.setItem('cookiecode', code);
    } 
  };
  
  saveCodeToLocalStorage();
  
  
  
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  const domain = window.location.hostname; // 获取当前域名
  return (
    <header className="flex flex-wrap items-center justify-start">
      {/* <NavLogo /> */}
      <NavMenu  dm={domain} /> 
      <div style={{padding: '0.5em',minHeight:'120px'}}>
      <p>
      <Link className="tags" to="/tags/韩国三级/">韩国三级</Link>
       
        <Link className="tags" to="/tags/调教虐待/">调教虐待</Link>
        <Link className="tags" to="/tags/年轻萝莉/">年轻萝莉</Link>
        <TagAds />
        {isExpanded && (
          <span>
            <Link className="tags" to="/tags/女上位/">女上位</Link>
            <Link className="tags" to="/tags/人妻熟女/">人妻熟女</Link>
            <Link className="tags" to="/tags/巨乳美臀/">巨乳美臀</Link>
            <Link className="tags" to="/tags/国产AV/">国产AV</Link>
            <Link className="tags" to="/口交深喉/">口交深喉</Link>
            <Link className="tags" to="/后入式/">后入式</Link>
            <Link className="tags" to="/老汉推车/">老汉推车</Link>
            <Link className="tags" to="/自拍偷拍/">自拍偷拍</Link>
            <Link className="tags" to="/凌辱调教/">凌辱调教</Link>
            <Link className="tags" to="/tags/女上男下/">女上男下</Link>
            <Link className="tags" to="/3P多P/">3P多P</Link>
            <Link className="tags" to="/tags/网红主播/"  id="">网红主播</Link>
            <Link className="tags" to="/tags/巨乳肥臀/" id="">巨乳肥臀</Link>
            <Link className="tags" to="/tags/家庭乱伦/">家庭乱伦</Link>
            <Link className="tags" to="/tags/丰满/" >丰满</Link>
            <Link className="tags" to="/tags/教师学生/" >教师学生</Link>
            <Link className="tags" to="/tags/制服/">制服</Link>
            <Link className="tags" to="/tags/极品女神/">极品女神</Link>
            <Link className="tags" to="/tags/特写/">特写</Link>
            <Link className="tags" to="/tags/自慰/">自慰</Link>
            <Link className="tags" to="/tags/奸夫淫妇/">奸夫淫妇</Link>
            <Link className="tags" to="/tags/舔逼/">舔逼</Link>
            <Link className="tags" to="/tags/双飞/">双飞</Link>
            <Link className="tags" to="/tags/丝袜/">丝袜</Link>
            <Link className="tags" to="/情趣内衣/">情趣内衣</Link>
            <Link className="tags" to="/tags/多人群P/">多人群P</Link>
            <Link className="tags" to="/tags/搭讪约炮/">搭讪约炮</Link>
            <Link className="tags" to="/tags/舔逼品玉/">舔逼品玉</Link>
            <Link className="tags" to="/tags/两男一女/">两男一女</Link>
            <Link className="tags" to="/tags/两女一男/">两女一男</Link>
            <Link className="tags" to="/tags/开房偷情/">开房偷情</Link>
            <Link className="tags" to="/tags/情趣丝袜/">情趣丝袜</Link>
            <Link className="tags" to="/tags/打打飞机/">打打飞机</Link>
            <Link className="tags" to="/tags/百合拉拉/">百合拉拉</Link>
            <Link className="tags" to="/tags/自慰喷水/">自慰喷水</Link>
            <Link className="tags" to="/tags/名模空姐/">名模空姐</Link>
            <Link className="tags" to="/tags/撸管/">撸管</Link>
            <Link className="tags" to="/tags/明星女优/">明星女优</Link>
            <Link className="tags" to="/tags/抠逼/">抠逼</Link>
            <Link className="tags" to="/tags/成人玩具/">成人玩具</Link>
            <Link className="tags" to="/tags/制服诱惑/">制服诱惑</Link>
            <Link className="tags" to="/tags/玩具/">玩具</Link>
            <Link className="tags" to="/tags/外围技师/">外围技师</Link>
            <Link className="tags" to="/tags/推油乳交/">推油乳交</Link>
            <Link className="tags" to="/tags/白虎/">白虎</Link>
            <Link className="tags" to="/tags/">查看更多</Link>
          </span>
        )}
        <button className="read-more-btn" onClick={toggleExpansion}>
          {isExpanded ? "收起" : "展开更多"}
        </button>
      </p>
    </div>
      <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">
        发任意内容回复最新地址 <Link to="mailto:33@3-dz.com?subject=最新地址找回"><b>33@3-dz.com </b></Link><br></br><br></br><Link to="https://x33gbuz.com" targer="_blank"><b>点此进入最新地址发布页（加入书签防迷路）</b></Link>
      </div>
      <TextAds />
    </header>
  );
};

const NavLogo = () => (
  <div className="w-full flex items-center justify-left">
    {/* <Logo /> */}
    <div className="flex items-center justify-right text-xs">
      <NavLink className="domainslogn_palyer" href="/"></NavLink>      
    </div>
  </div>
);

const NavMenu = ({dm}) => (
  <nav className="w-full">
    <div className="flex items-center justify-center text-center text-sc text-xl">{dm} 恭喜比特币突破8万美金</div>
    <ul className="w-full text-sm nav h-8 flex items-center justify-center text-center text-tw">
    <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/">首页</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/大陆/">大陆</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/日韩/">日韩</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/欧美/">欧美</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/三级/">三级</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/动漫/">动漫</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/短片/">短片</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/search/">搜索</NavLink></li>
    </ul>
  </nav>
);

export default Header;
